import React, { PropsWithChildren, useEffect, useRef, useState } from 'react';
import Close from './Modal.components/Close/Close';
import { useClickAway, useKeyPressEvent, useScrollbarWidth } from 'react-use';
import cn from 'classnames';

interface Props extends PropsWithChildren {
  onClose: () => void;
  show: boolean;
}

const Modal = ({ children, onClose, show }: Props) => {
  const [visible, setVisible] = useState(false);
  const ref = useRef(null);
  const scrollbarWidth = useScrollbarWidth();

  const handleClose = () => {
    setVisible(false);
    document.body.style.overflow = '';
    document.body.style.paddingRight = '';
    onClose();
  };

  useClickAway(ref, () => {
    handleClose();
  });

  useEffect(() => {
    if (show) {
      setVisible(true);
      document.body.style.overflow = 'hidden';
      document.body.style.paddingRight = `${scrollbarWidth}px`;
      setTimeout(() => {
        ref.current.focus();
      }, 50);
    }
  }, [show]);

  useKeyPressEvent('Escape', handleClose);

  return (
    <div
      className={cn(
        'w-full h-full fixed top-0 left-0 z-[60] overflow-x-hidden overflow-y-auto flex',
        visible ? 'opacity-100 visible' : 'invisible pointer-events-none opacity-0'
      )}
    >
      <div className='transition duration fixed inset-0 bg-gray-900 bg-opacity-50 dark:bg-opacity-80' />
      <div
        className={cn(
          'm-auto hs-overlay-open:mt-7 duration-300 ease-out transition-all sm:max-w-lg sm:w-full text-white ',
          visible ? 'opacity-100 translate-y-0' : 'opacity-0 -translate-y-16'
        )}
        tabIndex={-1}
        ref={ref}
      >
        <div className='relative flex flex-col bg-white shadow-lg rounded-xl dark:bg-gray-800'>
          <div className='absolute top-2 right-2'>
            <Close onClick={handleClose} />
          </div>

          <div className='p-4 sm:p-7 overflow-y-auto text-gray-800 dark:text-gray-200'>{children}</div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
