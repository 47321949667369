import React from 'react';
import { IProject, IUnit } from '../../Listing.types';
import ViewButton from '../ViewButton/ViewButton';

interface Props {
  item: IUnit & IProject;
  openItem: (item: IUnit & IProject) => void;
}

const TableItem = ({ item, openItem }: Props) => {
  const getUnitString = () => {
    if (!item.bed && !item.bath) {
      return 'N/A';
    }
    if (!item.bed) {
      return `${item.bath} 🛀🏼`;
    }
    if (!item.bath) {
      return `${item.bed} 🛌🏼`;
    }
    return `${item.bed} 🛌🏼 – ${item.bath} 🛀🏼`;
  };
  return (
    <tr>
      {/* <td className='h-px w-px whitespace-nowrap'>
        <div className='pl-6 py-3'>
          <label htmlFor='hs-at-with-checkboxes-1' className='flex'>
            <input
              type='checkbox'
              className='shrink-0 border-gray-200 rounded text-blue-600 pointer-events-none focus:ring-blue-500 dark:bg-gray-800 dark:border-gray-700 dark:checked:bg-blue-500 dark:checked:border-blue-500 dark:focus:ring-offset-gray-800'
              id='hs-at-with-checkboxes-1'
            />
            <span className='sr-only'>Checkbox</span>
          </label>
        </div>
      </td> */}
      <td className='h-px w-52 whitespace-nowrap'>
        <div className='px-5 py-3'>
          <span className='block text-sm font-semibold text-gray-800 dark:text-gray-200'>{item.project_name}</span>
          <span className='block text-sm text-gray-500'>{item.property_type}</span>
        </div>
      </td>
      <td className='h-px w-px whitespace-nowrap'>
        <div className='px-5 py-3'>
          <div className='flex items-center gap-x-3'>
            <span className='text-xs text-gray-500'>{getUnitString()}</span>
          </div>
        </div>
      </td>
      <td className='h-px w-px whitespace-nowrap'>
        <div className='px-5 py-3'>
          <div className='flex items-center gap-x-3'>
            <span className='text-xs text-gray-500'>
              {item.floor}
              {/* {unit.floor} – {unit.type} */}
            </span>
            {/* <!-- <span className='text-xs text-gray-500'>{unit.floor}/{unit.stories} – {unit.type}</span> --> */}
          </div>
        </div>
      </td>
      <td className='h-px w-px whitespace-nowrap'>
        <div className='px-5 py-3'>
          <div className='flex items-center gap-x-3'>
            <span className='text-xs text-gray-500'>{item.size}</span>
          </div>
        </div>
      </td>
      <td className='h-px w-36 whitespace-nowrap text-right'>
        <div className='px-5 py-3'>
          {item.price ? (
            <>
              <span className='block text-sm font-semibold text-gray-800 dark:text-gray-200'>
                {new Intl.NumberFormat('ru-RU').format(item.price)}
              </span>
              {item.size && (
                <span className='block text-sm text-gray-500'>
                  {new Intl.NumberFormat('ru-RU').format(Math.floor(item.price / item.size))}
                </span>
              )}
            </>
          ) : null}
        </div>
      </td>
      <td className='h-px w-px whitespace-nowrap'>
        <div className='px-5 py-3'>
          <span className='inline-flex items-center gap-1.5 py-0.5 px-2 rounded-full text-xs font-medium bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-200'>
            {item.ownership}
          </span>
        </div>
      </td>
      <td className='h-px w-px whitespace-nowrap'>
        <div className='px-5 py-3'>
          <span className='inline-flex items-center gap-1.5 py-0.5 px-2 rounded-full text-xs font-medium bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-200'>
            {item.view}
          </span>
        </div>
      </td>
      <td className='h-px w-80 whitespace-nowrap'>
        <div className='pl-5 lg:pl-3 xl:pl-0 pr-6 py-3'>
          <div className='flex items-center gap-x-3'>
            {/* {company.image && (
              <img
                className='inline-block h-[2.375rem] w-[2.375rem] rounded-full'
                src={company.image}
                alt='Image Description'
              />
            )} */}
            <div className='grow'>
              <span className='block text-sm font-semibold text-gray-800 dark:text-gray-200'>{item.source}</span>
              {item.ref && item.link && (
                <a href={item.link} rel='nofollow noreferrer' className='block text-sm text-gray-500' target='_blank'>
                  {item.ref}
                </a>
              )}
            </div>
          </div>
        </div>
      </td>
      <td className='h-px w-px whitespace-nowrap'>
        <div className='px-6 py-1.5'>
          <ViewButton onClick={() => openItem(item)} />
        </div>
      </td>
    </tr>
  );
};

export default TableItem;
