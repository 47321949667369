import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useLocation } from 'react-router-dom';

import getListings from '../../queries/getListings';
import { queryClient } from '../../';
import Spinner from '../Spinner/Spinner';

import TableHeader from './Listing.components/TableHeader/TableHeader';
import TableItem from './Listing.components/TableItem/TableItem';
import { IUnit, IProject } from './Listing.types';
import Footer from './Listing.components/Footer/Footer';
import Header from './Listing.components/Header/Header';
import Modal from '../Modal/Modal';

// interface Props {
//   id?: number | string;
// }

interface IPagination {
  page: number;
  pageSize: number;
  pageCount: number;
  total: number;
}

interface IPaginationAlt {
  start: number;
  limit: number;
  total: number;
}

const LISTING = 'listing';

const Listing = () => {
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [listing, setListing] = useState({} as Record<number, (IUnit & IProject)[]>);
  const [total, setTotal] = useState(0);
  const { search } = useLocation();
  const queryParams = useMemo(() => new URLSearchParams(search), [search]);
  const { status, data } = useQuery([LISTING, search, page], () => getListings({ page, searchParams: queryParams }), {
    refetchOnWindowFocus: false,
  });
  const [, setSelectedItem] = useState({});
  const [modalVisible, setModalVisible] = useState(false);

  const handleResultQuery = (result, pagination) => {
    const newlisting = { ...listing };
    newlisting[pagination.page] = result;
    setListing(newlisting);

    setPageCount(pagination.pageCount);
    setTotal(pagination.total);
  };

  const prefetchListing = async (page) => {
    await queryClient.prefetchQuery({
      queryKey: [LISTING, search, page],
      queryFn: () => getListings({ page, searchParams: queryParams }),
    });
    const listingData = queryClient.getQueryData([LISTING, search, page]) as Record<string, unknown>;
    if (!listingData) {
      return;
    }
    const {
      data,
      meta,
    }: { data?: Record<string, string>[]; meta?: Record<'pagination', IPagination | IPaginationAlt> } = listingData;
    handleResultQuery(data, meta.pagination);
  };

  useEffect(() => {
    if (!data) {
      return;
    }
    const result = data.data.map((item) => ({
      id: item.id,
      ...item.attributes,
    })) as (IUnit & IProject)[];

    const pagination = data.meta.pagination;

    handleResultQuery(result, pagination);
    // eslint-disable-next-line
  }, [data]);

  useEffect(() => {
    const nextPage = page + 1;
    if (nextPage <= pageCount && !listing[nextPage]) {
      prefetchListing(nextPage);
    }
    // eslint-disable-next-line
  }, [listing, page]);

  useEffect(() => {
    setPage(1);
  }, [search]);

  const handleNextPage = useCallback(() => {
    const newPage = page + 1;
    setPage(newPage);
  }, [page, setPage]);

  const handlePrevPage = useCallback(() => {
    const newPage = page - 1;
    setPage(newPage);
  }, [page, setPage]);

  const handleSelectItem = (item: IUnit & IProject) => {
    setSelectedItem(item);
    setModalVisible(true);
  };

  return (
    <>
      <div className='bg-white border border-gray-200 rounded-xl shadow-sm dark:bg-slate-900 dark:border-gray-700'>
        <Header />
        <div className={`${status !== 'loading' && 'overflow-x-auto'}`}>
          <table className='min-w-full divide-y divide-gray-200 dark:divide-gray-700'>
            <TableHeader />

            <tbody className='divide-y divide-gray-200 dark:divide-gray-700 relative'>
              {status === 'loading' ? (
                <tr className='h-64'>
                  <td className='absolute flex left-0 top-0 w-full h-full'>
                    <Spinner className='w-16 h-16 m-auto' />
                  </td>
                </tr>
              ) : (
                listing[page]?.map((row) => <TableItem key={row.id} item={row} openItem={handleSelectItem} />)
              )}
            </tbody>
          </table>
        </div>
        <Footer
          total={total}
          currentPage={page}
          countPages={pageCount}
          nextPage={handleNextPage}
          prevPage={handlePrevPage}
        />
      </div>
      <Modal show={modalVisible} onClose={() => setModalVisible(false)}>
        Not available yet
      </Modal>
    </>
  );
};

export default Listing;
