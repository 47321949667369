import React, { memo } from 'react';
import cn from 'classnames';
import { PropsWithClassName } from '../../../../shared/types';
import withPopup from '../../../../features/hocs/withPopup';
import MainMenuItem from '../MainMenuItem/MainMenuItem';
import { SizeFilter } from '../../../../features/filters/SizeFilter';
import { PriceFilter } from '../../../../features/filters/PriceFilter';
import OwnershipFilter from '../../../../features/filters/OwnershipFilter';
import { FloorFilter } from '../../../../features/filters/FloorFilter';
import { useSearchParams } from 'react-router-dom';
import { FILTERS_PARAMS_NAMES } from '../../../../utils/constants';

const MainMenu = ({ className }: PropsWithClassName) => {
  const SizeFilterWithPopup = withPopup(MainMenuItem, SizeFilter);
  const PriceFilterWithPopup = withPopup(MainMenuItem, PriceFilter);
  const OwnershipFilterWithPopup = withPopup(MainMenuItem, OwnershipFilter);
  const FloorFilterWithPopup = withPopup(MainMenuItem, FloorFilter);
  const [searchParams] = useSearchParams();

  return (
    <nav className={cn('spase-x-3 lg:space-x-4 order-1 lg:order-none lg:basis-auto grow items-center', className)}>
      <a
        href='/'
        className='text-white text-sm font-medium rounded-md bg-white bg-opacity-0 px-3 py-2 hover:bg-opacity-10'
        aria-current='page'
      >
        UPDATE
      </a>

      <SizeFilterWithPopup
        className={
          (searchParams.has(FILTERS_PARAMS_NAMES.size.from) || searchParams.has(FILTERS_PARAMS_NAMES.size.to)) &&
          'border-indigo-100 border'
        }
      >
        SIZE
      </SizeFilterWithPopup>

      <PriceFilterWithPopup
        className={
          (searchParams.has(FILTERS_PARAMS_NAMES.price.from) || searchParams.has(FILTERS_PARAMS_NAMES.price.to)) &&
          'border-indigo-100 border'
        }
      >
        SALE PRICE
      </PriceFilterWithPopup>
      <FloorFilterWithPopup
        className={
          (searchParams.has(FILTERS_PARAMS_NAMES.floor.from) || searchParams.has(FILTERS_PARAMS_NAMES.floor.to)) &&
          'border-indigo-100 border'
        }
      >
        FLOOR
      </FloorFilterWithPopup>

      <OwnershipFilterWithPopup
        className={searchParams.has(FILTERS_PARAMS_NAMES.ownership) && 'border-indigo-100 border'}
      >
        OWNERSHIP
      </OwnershipFilterWithPopup>
    </nav>
  );
};

export default memo(MainMenu);
