import { API } from '../utils/constants';
import request from '../utils/request';

const searchProject = async (searchString) => {
  const requestSUrl = `${API}/autolistings?pagination[limit]=50&filters[project_name][$containsi]=${searchString}`;

  if (!searchString) {
    return {
      status: 'success',
      data: [],
    };
  }
  return await request(requestSUrl);
};

export default searchProject;
