import { API, COUNT_RECORDS_ON_PAGE } from '../utils/constants';
import { addQueryParams } from '../utils/helpers';
import request from '../utils/request';

const getListings = async ({ page, searchParams }: { page: number; searchParams: URLSearchParams }) => {
  const resUrl = new URL(`${API}/autolistings`);

  addQueryParams({ url: resUrl, searchParams });

  resUrl.searchParams.append('pagination[pageSize]', String(COUNT_RECORDS_ON_PAGE));
  resUrl.searchParams.append('pagination[page]', String(page));
  // resUrl.searchParams.append('populate[project_name][populate][0]', 'property_type');
  // resUrl.searchParams.append('populate[company]', '1');
  // if (id) {
  //   url.searchParams.append('filters[project_name][id]', String(id));
  // }

  return await request(resUrl.toString());
};

export default getListings;
