import React, { useRef, useState } from 'react';

import Notification from '../Notification/Notification';
import Profile from '../Profile/Profile';
import Logo from './Header.components/Logo/Logo';
import Search from './Header.components/Search/Search';
import MenuButton from './Header.components/MenuButton/MenuButton';
import MainMenu from './Header.components/MainMenu/MainMenu';
import MobileMenu from './Header.components/MobileMenu/MobileMenu';
import AltMenu from './Header.components/AltMenu/AltMenu';
import Container from '../Container/Container';
import { useClickAway } from 'react-use';
import cn from 'classnames';

const HeaderContent = () => {
  const [active, setActive] = useState(false);
  const ref = useRef(null);

  useClickAway(ref, () => {
    setActive(false);
  });

  const toggleMenu = () => {
    setActive(!active);
  };

  return (
    <div className='bg-indigo-600'>
      <div ref={ref} className=' relative z-20'>
        <Container className='relative flex items-center justify-center py-5 md:justify-between flex-wrap w-full bg-indigo-600 z-20'>
          <Logo />
          {/* <AltMenu className='hidden md:flex' /> */}
          <AltMenu className='hidden' />

          <div className='hidden md:flex lg:items-center justify-end md:pr-0.5 order-1 md:order-none'>
            <Notification />
            <Profile />
          </div>

          <Search />

          <MenuButton active={active} toggleMenu={toggleMenu} />

          <div className='h-[1px] my-5 bg-white bg-opacity-20 w-full basis-full order-none relative z-20 sm:z-0' />

          <MainMenu className='md:flex hidden' />
        </Container>
        <MobileMenu active={active} />
      </div>
      <div className={cn('inset-0 z-0 bg-black bg-opacity-40', { fixed: active })} />
    </div>
  );
};

export default HeaderContent;
