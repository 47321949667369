import React, { useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import { PropsWithClassName } from '../../../../shared/types';
import { useForm } from 'react-hook-form';
import searchProject from '../../../../queries/searchProject';
import Spinner from '../../../Spinner/Spinner';
import { useQuery } from '@tanstack/react-query';
import { EMPTY_SEARCH_RESULT, INITIAL_SEARCH_RESULT } from '../../../../utils/constants';
import SearchItem from '../SearchItem/SearchItem';
import { queryClient } from '../../../../';
import { useNavigate } from 'react-router-dom';
import { useClickAway } from 'react-use';

const SEARCH = 'search';

interface IFormInput {
  [SEARCH]: string;
}

const Search = ({ className }: PropsWithClassName) => {
  const { register, watch, setValue } = useForm<IFormInput>();
  const [suggest, setSuggest] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const watchSearch = watch(SEARCH);
  const { status, data } = useQuery([SEARCH, searchValue], () => searchProject(searchValue));

  const [showSuggest, setShowSuggest] = useState(false);
  const navigate = useNavigate();
  const ref = useRef(null);

  useEffect(() => {
    queryClient.cancelQueries([SEARCH, searchValue]);
    if (watchSearch) {
      setSearchValue(watchSearch);
    }
    // eslint-disable-next-line
  }, [watchSearch]);

  const handleFocus = () => {
    setShowSuggest(true);
  };

  useClickAway(ref, () => {
    setShowSuggest(false);
  });

  useEffect(() => {
    if (data && data?.data?.length >= 0) {
      const filtered = new Map();
      data.data.forEach((item) => {
        const name = item.attributes.project_name.trim();
        if (!filtered.has(name)) {
          filtered.set(name, item.id);
        }
      });
      const res = [];
      for (const [name, id] of filtered) {
        res.push({ id, name });
      }
      setSuggest(res);
    }
  }, [data]);

  const handleProjectClick = (projectName: string) => {
    setShowSuggest(false);
    setValue(SEARCH, '');
    navigate(`/?project_name=${projectName}`);
  };

  return (
    <div
      ref={ref}
      className={cn(
        'min-w-0 order-3 md:order-3 xs:order-none w-full max-w-xs md:max-w-[275px] lg:max-w-sm mx-auto md:ml-auto md:mr-0 relative z-10',
        className
      )}
    >
      <label htmlFor={SEARCH} className='sr-only'>
        Search
      </label>
      <div className='relative text-white focus-within:text-gray-600'>
        <div className='pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3'>
          <svg
            className='h-5 w-5'
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 20 20'
            fill='currentColor'
            aria-hidden='true'
          >
            <path
              fillRule='evenodd'
              d='M9 3.5a5.5 5.5 0 100 11 5.5 5.5 0 000-11zM2 9a7 7 0 1112.452 4.391l3.328 3.329a.75.75 0 11-1.06 1.06l-3.329-3.328A7 7 0 012 9z'
              clipRule='evenodd'
            />
          </svg>
        </div>
        <input
          id={SEARCH}
          autoComplete='off'
          className='block w-full rounded-md border border-transparent bg-indigo-500 py-2 pl-10 pr-3 leading-5 text-gray-900 placeholder-white focus:border-transparent focus:bg-white focus:placeholder-gray-500 focus:outline-none focus:ring-0 sm:text-sm'
          placeholder='Search'
          type='search'
          {...register(SEARCH)}
          onFocus={handleFocus}
        />
      </div>
      <div
        className={cn(
          'absolute left-0 w-full top-0 bg-indigo-600 border border-white rounded-md pt-[38px] -z-10 max-h-64 overflow-y-auto',
          { hidden: !showSuggest, 'h-28 flex': status === 'loading' && searchValue }
        )}
      >
        {status === 'loading' && searchValue && (
          <div className='p-2 m-auto h-14 w-14 overflow-hidden'>
            <Spinner className='h-full w-full' />
          </div>
        )}
        {((status === 'loading' && !searchValue) || (status !== 'loading' && !searchValue)) && (
          <SearchItem>{INITIAL_SEARCH_RESULT}</SearchItem>
        )}
        {status === 'success' && searchValue && !suggest?.length && <SearchItem>{EMPTY_SEARCH_RESULT}</SearchItem>}
        {status === 'success' && suggest?.length
          ? suggest?.map((item) => {
              return (
                <SearchItem onClick={() => handleProjectClick(item.name)} key={item.id}>
                  {item.name}
                </SearchItem>
              );
            })
          : null}
      </div>
    </div>
  );
};

export default Search;
